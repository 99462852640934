/* stylelint-disable selector-class-pattern */

@import "~@comanage/bootstrap/variables";

.fa-Vendor::before {
  content: $fa-var-building-o;
}

.fa-Manager::before {
  content: $fa-var-bank;
}

.fa-Owner::before {
  content: $fa-var-users;
}

.fa-Tenant::before {
  content: $fa-var-child;
}
