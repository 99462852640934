// Well, this is just the select2 3.5.1 bootstrap 3 code, with added .form-select selectors.
// It's good enough until we can upgrade to select2 v4
@import "~@comanage/bootstrap/variables";

.select2-dropdown-open .select2-choice,
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-ajax-error,
.select2-results .select2-selection-limit {
  background: unset;
  background-color: unset;
  background-image: none;
}

.select2-container {
  .select2-choice {
    height: 100%;
  }

  .select2-choice {
    line-height: $line-height-base;
  }

  .select2-choice {
    background-image: none;

    .select2-arrow {
      background: unset;
      background-image: unset;
    }
  }
}

.select2-drop {
  border-radius: 0 0 $input-border-radius $input-border-radius;
}

.form-control.select2-container,
.form-select.select2-container {
  padding: 0;

  .select2-choice {
    padding: $form-select-padding-y $form-select-padding-x;

    .select2-arrow {
      border-left: 0;
    }
  }
}

.form-select.select2-container {
  .select2-arrow {
    display: none;
  }
}

.form-select,
.form-control {
  &.select2-container {
    height: auto !important;
    //padding: 0;

    &.select2-dropdown-open {
      border-color: $input-focus-border-color;
      border-radius: 0;

      .select2-choices {
        border-radius: 0;
      }
    }
  }

  .select2-choice {
    border: 0;
    border-radius: 0;

    .select2-arrow {
      border-radius: 0;
    }
  }

  .select2-choices {
    border: 0 !important;
    border-radius: 0;

    .select2-search-choice {
      margin: 6px 0 6px 5px;
    }
  }
}

.control-group.warning {
  .select2-container .select2-choice div {
    border-left: 1px solid $warning !important;
    background: #fcf8e3 !important;
  }

  .select2-container,
  .select2-container-active,
  .select2-dropdown-open.select2-drop-above,
  .select2-container-multi.select2-container-active {
    .select2-choice,
    .select2-choices {
      border: 1px solid $warning !important;
    }
  }
}

.control-group.error,
.control-group.warning .control-group.error {
  .select2-container,
  .select2-container-active,
  .select2-dropdown-open.select2-drop-above,
  .select2-container-multi.select2-container-active {
    .select2-choice,
    .select2-choices {
      border: 1px solid $danger !important;
    }
  }
}

.control-group.error .select2-container .select2-choice div {
  border-left: 1px solid $danger !important;
  background: #f2dede !important;
}

.control-group.info {
  .select2-container,
  .select2-container-active,
  .select2-dropdown-open.select2-drop-above,
  .select2-container-multi.select2-container-active {
    .select2-choice,
    .select2-choices {
      border: 1px solid $info !important;
    }
  }

  .select2-container .select2-choice div {
    border-left: 1px solid $info !important;
    background: #d9edf7 !important;
  }
}

.control-group.success {
  .select2-container,
  .select2-container-active,
  .select2-dropdown-open.select2-drop-above,
  .select2-container-multi.select2-container-active {
    .select2-choice,
    .select2-choices {
      border: 1px solid $success !important;
    }
  }

  .select2-container .select2-choice div {
    border-left: 1px solid $success !important;
    background: #dff0d8 !important;
  }
}
