.password-strength {
  width: 20%;
  border: 2px solid #8b0000;
  margin-top: 0;

  transition: border-color linear 0.3s, width linear 0.3s;

  &[data-strength="1"] {
    width: 40%;
    border-color: #ffff00;
  }

  &[data-strength="2"] {
    width: 60%;
    border-color: #adff2f;
  }

  &[data-strength="3"] {
    width: 80%;
    border-color: #9acd32;
  }

  &[data-strength="4"] {
    width: 100%;
    border-color: #008000;
  }
}
