@import "contact";
@import "invoice";
@import "occupancy";
@import "offer";
@import "organization";
@import "payment";
@import "property";
@import "transaction";
@import "universal";
@import "workorder";

.event-timeline {
  //width: 578px;
  border-left: 1px #d3d3d3 solid;
  margin-left: 12px;

  &::before {
    border-left: 1px #808080 solid;
  }

  .event {
    //width: 550px;
    padding: 5px 0 0;
    margin-left: 12px;
    font-weight: normal;

    b {
      color: #111111;
      font-weight: normal;
    }

    .details {
      display: block;
      padding-left: 20px;
      font-size: 0.9em;
    }

    .event-icon {
      @extend .fa;
      @extend .fa-fw;

      display: inline-block;
      width: 24px;
      height: 22px;
      padding: 4px 5px;
      margin: 2px 7px -6px -20px;
      background: #eeeeee;
      border-radius: 12px;
      color: #888888;
    }

    &.new-event {
      padding: 0;
      margin-left: 21px;
      text-align: right;

      .user-input {
        border: 1px lightgray solid;
        margin-bottom: 3px;
        border-radius: 5px;
        text-align: left;

        &.is-dragging {
          background-color: #f8f8ff;
        }

        &.is-dragover {
          background-color: #eeeeff;
          cursor: copy;
        }

        textarea {
          width: 100%;
          border: none;
          background-color: transparent;
          outline: none;
          resize: none;
        }

        input[type="file"] {
          position: absolute;
          top: -1000px;
          left: -1000px;
        }

        .attachments {
          width: 100%;
          padding: 2px 2px 0;
          border-top: 1px lightgray dashed;
          font-size: 0.8em;

          label {
            padding: 0;
            color: #428bca;
            font-weight: normal;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &.not-droppable .attachments .is-droppable {
          display: none;
        }
      }

      .controls {
        overflow: hidden;
        max-height: 0;
        text-align: left;
        transition: max-height 250ms;

        label {
          margin-top: 0;
          margin-bottom: 0;

          &:first-child {
            margin-right: 10px;
          }
        }
      }

      &.not-empty {
        .controls {
          max-height: 999px;
        }
      }
    }
  }

  div.event {
    color: #666666;
  }
}
