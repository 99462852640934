/* stylelint-disable selector-class-pattern */

@import "~@comanage/bootstrap/variables";

table.notification {
  tbody.subscriptions {
    tr.unread {
      &.urgent {
        background-color: shift-color($body-color, $alert-bg-scale);
      }
    }

    tr.read {
      background-color: shift-color($body-color, $table-bg-scale);
      color: $text-muted;

      &,
      * {
        font-weight: normal !important;
      }
    }
  }

  tbody.notifications {
    tr.notification-Unread {
      &,
      td {
        background-color: shift-color($danger, $alert-bg-scale);
      }
    }

    tr.notification-Read {
      &,
      td {
        background-color: shift-color($warning, $alert-bg-scale);
      }
    }

    tr.notification-Started {
      &,
      td {
        background-color: shift-color($info, $alert-bg-scale);
      }
    }

    tr.notification-Working {
      &,
      td {
        background-color: shift-color($success, $alert-bg-scale);
      }
    }

    tr.notification-Completed {
      &,
      td {
        background-color: shift-color($body-bg, $table-bg-scale);
        color: $text-muted;
      }
    }
  }
}
