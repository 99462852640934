@use "sass:math";

@import "~@comanage/bootstrap/variables";

dl.dl-accounting {
  dt {
    overflow: hidden;
    width: 310px;
    clear: left;
    float: left;
    font-weight: 600;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.heading {
      width: 100%;
      text-decoration: underline;
    }
  }

  dd {
    margin-left: 320px;
    text-align: right;

    &.seperator {
      min-height: math.div($line-height-base, 1.5);

      + dd.seperator {
        display: none;
      }
    }

    &.value::before {
      content: "$";
      float: left;
    }
  }

  dt.heading + dd {
    width: 0;
  }
}
