// stylelint-disable
@use "sass:math";

@import "~@comanage/bootstrap/variables";

.main-container {
  min-height: 100vh;
}

#navbar {
  margin-top: -1.2em;
}

#search-query {
  min-width: 160px;
}

* + legend {
  //TODO: Remove this, multiple legends are not supported for a fieldset
  margin-top: $line-height-base * 1.5;
}

// Add padding to multiple rows of fieldsets for spacing and readability
fieldset + fieldset,
fieldset + div,
div + fieldset {
  margin-top: $line-height-base * 1;
}

.content {
  @include make-container();
}

.fade-edges {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
}

$content-width-lg: 10;
$content-width-md: 9;

.content-main {
  @extend .col-lg-10;
  @extend .col-md-9;

  @extend .order-lg-2;
  @extend .order-md-2;
}

.content-main-contact {
  width: 100%;
}

.content-sidebar {
  @extend .col-lg-2;
  @extend .col-md-3;

  @extend .order-lg-4;
  @extend .order-md-4;
}

.col-full {
  @extend .col-12;
}

.col-half {
  @extend .col-12;
  @extend .col-md-6;
}

.col-two-third {
  @extend .col-12;
  @extend .col-md-8;
}

.col-third {
  @extend .col-12;
  @extend .col-md-4;
}

.col-three-quarter {
  @extend .col-12;
  @extend .col-md-9;
}

.col-quarter {
  @extend .col-6;
  @extend .col-md-3;
}

// .col-half-pull {
//    @include make-md-column-pull(6);
//    @include make-lg-column-pull(6);
// }

// .col-half-push {
//    @include make-md-column-push(6);
//    @include make-lg-column-push(6);
// }

// .col-half-offset {
//    @include make-md-column-offset(6);
//    @include make-lg-column-offset(6);
// }

// .col-third-pull {
//    @include make-md-column-pull(4);
//    @include make-lg-column-pull(4);
// }

// .col-third-push {
//    @include make-md-column-push(4);
//    @include make-lg-column-push(4);
// }

// .col-third-offset {
//    @include make-md-column-offset(4);
//    @include make-lg-column-offset(4);
// }

// .col-quarter-pull {
//    @include make-md-column-pull(3);
//    @include make-lg-column-pull(3);
// }

// .col-quarter-push {
//    @include make-md-column-push(3);
//    @include make-lg-column-push(3);
// }

// .col-quarter-offset {
//    @include make-md-column-offset(3);
//    @include make-lg-column-offset(3);
// }

// .col-two-third-pull {
//    @include make-md-column-pull(8);
//    @include make-lg-column-pull(8);
// }

// .col-two-third-push {
//    @include make-md-column-push(8);
//    @include make-lg-column-push(8);
// }

.col-left {
  @extend .col-md-5;
  @extend .col-lg-4;
}

.col-left-offset {
  @include media-breakpoint-up(md) {
    @include make-col-offset(5);
  }
  @include media-breakpoint-up(lg) {
    @include make-col-offset(4);
  }
}

.col-right {
  @extend .col-md-7;
  @extend .col-lg-8;
}

.col-left-lg {
  @extend .col-lg-8;
  @extend .col-md-7;
}

.col-left-sm {
  // @include make-lg-column(2.5);
  @extend .col-md-2;
}

.col-right-lg {
  // @include make-lg-column(9.5);
  @extend .col-md-10;
}

.col-right-sm {
  @extend .col-lg-4;
  @extend .col-md-5;
}

.col-half .col-left {
  @extend .col-sm-4;
}

.col-half .col-right {
  @extend .col-sm-8;
}

.col-label {
  @include media-breakpoint-up(md) {
    text-align: right;
  }

  font-weight: bold;
}

.col-form-left {
  @extend .col-lg-3;
  @extend .col-md-4;
}

.col-form-right {
  @extend .col-lg-9;
  @extend .col-md-8;
}

.registration-form {
  .col-form-left {
    @extend .col-lg-3;
    @extend .col-md-4;
  }

  .col-form-right {
    @extend .col-lg-9;
    @extend .col-md-8;
  }
}

// .col-form-offset {
//   @include make-lg-column-offset(2);
//   @include make-lg-column-offset(3);
// }

.col-form-left-lg {
  @extend .col-lg-4;
  @extend .col-md-5;
}

.col-form-right-sm {
  @extend .col-lg-8;
  @extend .col-md-7;
}

.col-2col-left {
  @extend .col-12;
  @extend .col-md-7;
}

.col-2col-right {
  @extend .col-12;
  @extend .col-md-5;
}

.col-no-padding-left {
  padding-left: 0;
}

.col-no-padding-right {
  padding-right: 0;
}

.row-no-padding > * {
  &:not(:first-child) {
    @extend .col-no-padding-left;
  }

  &:not(:last-child) {
    @extend .col-no-padding-right;
  }
}

.col-no-padding {
  @extend .col-no-padding-left;
  @extend .col-no-padding-right;
}

// I'm sorry for all the `!important`s, they're kinda necessary for things like `d-none d-modal-...` to work
// This could be replaced with `d-none d-modal-unset` I think. Investigate?
.visible-modal {
  display: none;
}

.modal {
  .d-modal-none,
  .hidden-modal {
    display: none !important;
  }

  .visible-modal {
    display: unset !important;
  }

  .d-modal-unset {
    display: unset !important;
  }

  .d-modal-block {
    display: block !important;
  }

  .d-modal-inline-block {
    display: inline-block !important;
  }

  .modal-dialog.modal-xl {
    width: calc(100% - 20px);

    @include media-breakpoint-up(sm) {
      width: calc(100% - 60px);
    }
  }
}

.text-indent-xs {
  text-indent: 10px;
}

.text-indent-sm {
  text-indent: 25px;
}

.text-indent-md {
  text-indent: 50px;
}

.text-indent-lg {
  text-indent: 100px;
}

span.text-indent-xs {
  padding-left: 10px;
}

span.text-indent-sm {
  padding-left: 25px;
}

span.text-indent-md {
  padding-left: 50px;
}

span.text-indent-lg {
  padding-left: 100px;
}

.strike {
  text-decoration: line-through;
}

.strike a:hover,
a.strike:hover {
  text-decoration: line-through;
}

.content-sidebar .list-group .list-group-item {
  padding: 7px 5px;
  // border: none;
}

.content-sidebar .list-group .list-group-spacer {
  padding: 10px 0;
}

dl.dl-horizontal.dl-horizontal-small {
  dt {
    width: 100px;
  }

  dd {
    margin-left: 120px;
  }
}

dl.dl-no-padding {
  margin-top: 0;
  margin-bottom: 0;
}

// table.table td .btn {
//   margin-top: -8px;
//   margin-bottom: -8px;
// }

table.table.table-sm td .btn:only-child,
.panel .panel-heading .panel-title .btn {
  margin-top: -8px;
  margin-bottom: -8px;
}

legend .legend-controls {
  @extend .pull-right;
  @extend .btn-group;

  padding-right: 1px;
  margin-top: 1px;

  .dropdown {
    margin-top: -2px;
    float: left;
  }

  .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .btn,
  .dropdown .btn {
    @extend .btn-sm;
    border-color: $border-color;
    border-bottom: 0;

    border-radius: 0;
  }

  .btn:first-child,
  .dropdown:first-child .btn {
    border-top-left-radius: 3px;
  }

  .btn:last-child,
  .dropdown:last-child .btn {
    border-top-right-radius: 3px;
  }
}

.table {
  tbody > tr > .table-controls,
  thead > tr > .table-controls {
    padding: 1px;
    text-align: right;

    .btn {
      margin: 0;
    }
  }

  &.table-sm {
    thead > tr > .table-controls .btn,
    tbody > tr > .table-controls .btn {
      padding: 3px 10px;
    }
  }
}

// rules for <legend> from browser and `node_modules/bootstrap/scss/_reboot.scss`
.legend,
.table-legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border: none;
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: $legend-margin-bottom;
  @include font-size($legend-font-size);
  font-weight: $legend-font-weight;
  line-height: inherit;

  + * {
    clear: left;
  }
}

legend,
.legend,
.table-legend {
  margin-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
  line-height: 1.3;
}

.table-legend + table {
  @extend .table-bordered;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .controls {
    @extend .pull-right;
    @extend .btn-group;

    padding-right: 1px;
  }
}

h4,
h5,
h6 {
  .controls {
    margin-top: -(math.div($line-height-base, 2));
  }
}

div.loading {
  position: fixed;
  z-index: 500000;
  top: 0;
  left: 0;
  display: table;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;

  div {
    display: table-cell;
    vertical-align: middle;
  }

  div .message {
    display: block;
    width: 100%;
    font-size: 3em;
    text-align: center;
  }
}

legend + .list-group {
  margin-top: -21px;
}

.flask-pagedown-preview {
  padding: 8px;
  border: 1px dashed #808080;
  margin-top: 6px;
  background-color: #eeeeee;
}

.note-header {
  &,
  &.note-event {
    background-color: #ffffe0;
  }

  &.note-note {
    background-color: #eeeeff;
  }

  &.note-manager {
    background-color: #eeffee;
  }

  &.note-vendor {
    background-color: #ddeeee;
  }
}

.modal-backdrop {
  z-index: 1040;
}

div.modal {
  .d-modal-none {
    display: none;
  }

  .modal-body {
    overflow: auto;
    max-height: 400px;
    overflow-x: hidden;
  }
}

.table.table-narrow thead tr th {
  padding-right: 0;
  padding-left: 0;
}

#zenbox_tab {
  top: 80% !important;
}

.view footer .branding {
  display: none;
}

.nav > li > a {
  padding-right: 9px;
  padding-left: 9px;
}

.no-bottom-padding {
  padding-bottom: 0;
  margin-bottom: 0;
}

.upgrade-browser {
  position: fixed;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  padding-top: 10px;
  background-color: #ffa500;
  text-align: center;
}

.btn::before {
  width: 0;
  content: "";
  transition: all 0.4s;
}

.btn[loading]::before {
  @extend .fa;
  @extend .fa-spin;
  @extend .fa-fw;

  content: $fa-var-spinner;
}

@media only screen {
  .one-line {
    display: block;
    overflow: hidden;
    height: $line-height-base;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.monospace {
  font-family: $font-family-monospace;
}

.font-small {
  font-size: $small-font-size;
}

td.one-line {
  display: table-cell;
}

address {
  display: inline;
}

table.table {
  .row-danger {
    background-color: shift-color($danger, $alert-bg-scale);
  }
}

table.table.table-striped {
  .row-danger {
    &:nth-child(odd) {
      background-color: shift-color($danger, $alert-bg-scale);
    }

    &:nth-child(even) {
      background-color: lighten(shift-color($danger, $alert-bg-scale), 2%);
    }
  }
}

ul.nav.nav-short > li > a {
  padding-top: 4px;
  padding-bottom: 4px;
}

@media print {
  a[href]::after {
    content: none !important;
  }
}

.textarea--autosized {
  resize: none;
}
