@import "~@comanage/bootstrap/variables";

.dashboard {
  .card-dashboard {
    //border-color: $border-color;

    .card-header {
      //border-color: $border-color;
      //background-color: shift-color($body-bg, $alert-bg-scale);

      i {
        transform: rotate(0deg);
        transition: all 0.5s ease;
      }

      &:hover i {
        transform: scale(1.2);
      }
    }

    .card-body {
      border-color: $border-color;
    }

    &.open {
      .card-header {
        i {
          transform: rotate(90deg);
        }

        &:hover i {
          transform: scale(1.2) rotate(90deg);
        }
      }
    }
  }

  .graph-bars {
    display: flex;
    width: 100%;
    min-height: 2em;

    .graph-bar {
      display: block;
      min-width: 8em;
      min-height: inherit;
      flex-basis: auto;
      flex-grow: 1;
      padding: 8px;

      &.graph-bar-danger {
        border-bottom: 5px solid mix($danger, $border-color);
        background-color: shift-color($danger, $alert-bg-scale);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        p,
        span {
          color: shift-color($danger, $alert-color-scale);
        }
      }

      &.graph-bar-warning {
        border-bottom: 5px solid shift-color($warning, $alert-border-scale);
        background-color: shift-color($warning, $alert-bg-scale);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        p,
        span {
          color: shift-color($warning, $alert-color-scale);
        }
      }

      &.graph-bar-info {
        border-bottom: 5px solid shift-color($info, $alert-border-scale);
        background-color: shift-color($info, $alert-bg-scale);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        p,
        span {
          color: shift-color($info, $alert-color-scale);
        }
      }

      &.graph-bar-success {
        border-bottom: 5px solid shift-color($success, $alert-border-scale);
        background-color: shift-color($success, $alert-bg-scale);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        p,
        span {
          color: shift-color($success, $alert-color-scale);
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 4px;
      }

      a,
      p {
        margin: 4px;
      }
    }
  }
}
