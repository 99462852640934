/* stylelint-disable selector-class-pattern */

@import "~@comanage/bootstrap/variables";

.invoice-new {
  .invoice-section:not(:first-child) {
    margin-top: 10px;
  }

  .invoice-label,
  .invoice-total-row {
    font-weight: bold;
  }

  .invoice-block {
    float: right !important;
  }

  .invoice-description {
    padding: 15px 30px 5px 5px;
  }

  .column-price,
  .column-subtotal {
    text-align: right;
    white-space: nowrap;
  }
}

// TODO: destroy this and change .invoice-new to .invoice
.invoice {
  $thead-bottom-border: 1px #e5e5e5 solid;
  $tbody-bottom-border: 1px #f0f0f0 solid;
  $tfoot-bottom-border: 1px #f0f0f0 solid;
  $legend-border-bottom-color: #e5e5e5;
  $odd-row-color: #fcfcfc;
  $invalid-even-color: #ffdddd;
  $invalid-odd-color: darken(#ffdddd, 2%);

  table {
    width: 100%;

    tr {
      &.transaction-payment {
        font-weight: bold;

        .payment-column,
        .invoice-column {
          white-space: nowrap;
        }
      }

      th {
        vertical-align: bottom;
      }

      td {
        padding: 4px 8px 4px 0;
        margin: 0;
        vertical-align: top;

        &.money {
          text-align: right;
          //font-family: "Droid Sans Mono", Menlo, Monaco, monospace;
          &::before {
            content: "$";
          }
        }

        &.percent {
          text-align: right;
          //font-family: "Droid Sans Mono", Menlo, Monaco, monospace;
          &::after {
            content: "%";
          }
        }
      }
    }
  }

  .line_items {
    legend {
      border-bottom-color: $legend-border-bottom-color;
      margin-bottom: 0;
    }

    .has-error {
      .form-text {
        color: $danger;
      }
    }

    table {
      tr.has-error {
        color: $danger;

        td {
          font-weight: bold;

          input {
            border-color: shift-color($danger, $alert-border-scale);
            font-weight: normal;
          }
        }
      }

      thead {
        tr {
          border-bottom: $thead-bottom-border;

          th {
            text-align: left;

            &.name {
              width: 140px;
            }

            &.taxable {
              width: 70px;
              text-align: right;
            }

            &.count {
              width: 70px;
              text-align: right;
            }

            &.price,
            &.subtotal {
              width: 100px;
              text-align: right;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: $tbody-bottom-border;

          &:nth-child(2) {
            background-color: $odd-row-color;
          }

          &.invalid {
            background-color: $invalid-even-color !important;

            input,
            .select2-container .select2-choice {
              border-color: darken($invalid-even-color, 15%) !important;
            }

            &:nth-child(2) {
              background-color: $invalid-odd-color !important;

              input,
              .select2-container .select2-choice {
                border-color: darken($invalid-odd-color, 15%) !important;
              }
            }
          }

          td {
            input {
              width: 100%;

              &[type="number"] {
                display: inline-block;
                padding: 4px;
                border: 1px solid #cccccc;
                border-radius: 3px;
                color: #555555;
                font-size: 13px;
                line-height: 18px;
                text-align: right;
              }
            }

            .select2-container {
              width: 100%;
            }

            input[type="checkbox"],
            input[type="radio"] {
              height: 18px;
            }

            &.description {
              .select2-container {
                margin: 0 0 0 1px;

                .select2-choice {
                  height: 28px;
                  border-radius: 3px;
                  line-height: 18px;

                  .select2-chosen {
                    padding: 4px;
                  }

                  .select2-arrow {
                    b {
                      background-position: 0 1px;
                    }
                  }
                }
              }
            }

            &.taxable {
              text-align: right;
            }

            &.count {
              text-align: right;
            }

            &.price {
              text-align: right;

              input {
                width: 80px;
              }

              &::before {
                padding-right: 2px;
                content: "$";
              }
            }

            &.subtotal {
              text-align: right;

              &::before {
                content: "$";
              }
            }
          }
        }
      }

      tfoot {
        tr {
          td {
            input {
              width: 100%;
              border-top: 0;
              border-right: 0;
              border-left: 0;
              text-align: right;
            }

            #description {
              width: 100%;
              height: 100%;
              text-align: left;
            }

            &:last-of-type,
            &:nth-last-of-type(2) {
              border-bottom: $tfoot-bottom-border;
            }
          }

          &:last-of-type {
            td {
              border: 0;
            }
          }
        }
      }
    }
  }

  fieldset.bills,
  fieldset.payments,
  fieldset.fees,
  fieldset.terms {
    legend {
      border-bottom-color: $legend-border-bottom-color;
      margin-bottom: 0;
    }

    table {
      width: 100%;
      padding-top: 0;
      margin-top: 0;
      margin-bottom: 0;

      td,
      th {
        padding: 0 10px;
      }

      thead {
        tr {
          //line-height: 2.1em;
          width: 100%;
          border-bottom: $thead-bottom-border;

          th {
            &.money {
              text-align: right;
            }
          }
        }
      }

      tbody {
        tr {
          width: 100%;
          border-bottom: $tbody-bottom-border;
          line-height: 1.8em;

          &:nth-child(2) {
            background-color: $odd-row-color;
          }
        }
      }

      tfoot {
        tr {
          td {
            font-weight: 600;
          }
        }
      }

      tbody,
      tfoot {
        tr {
          td {
            &.money {
              text-align: right;

              &::before {
                content: "$";
              }
            }
          }
        }
      }
    }
  }

  .terms_and_conditions {
    .col-form-left {
      text-align: right;

      select {
        padding: 0;
        border-width: 0;
        border-bottom-width: 1px;
        margin: 0;
        background-color: transparent;
        //width: 100%;
        font-weight: bold;
        text-align: right;
      }
    }
  }

  fieldset.fees {
    table {
      width: 50%;
    }
  }

  fieldset.description {
    legend {
      margin-bottom: 3px;
    }
  }

  fieldset.terms {
    legend {
      margin-bottom: 0;
    }

    div {
      overflow: auto;
      max-height: 100px;
      border: 1px #d3d3d3 solid;
    }
  }

  fieldset.expando {
    legend {
      user-select: none;

      label {
        width: 100%;
        font-weight: 400;
      }
    }

    .check_expander {
      display: none;

      &:checked + legend > label::after {
        content: "\2303";
      }

      &:not(:checked) + legend {
        border: 0;

        + * {
          display: none;
        }

        label::after {
          content: "\2304";
        }
      }
    }
  }
}
