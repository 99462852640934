@import "~@comanage/bootstrap/variables";

$list-inline-margin: 5px;

.color-primary {
  &,
  &.fa::before {
    color: $primary !important;
  }
}

.color-success {
  &,
  &.fa::before {
    color: $success !important;
  }
}

.color-info {
  &,
  &.fa::before {
    color: $info !important;
  }
}

.color-warning {
  &,
  &.fa::before {
    color: $warning !important;
  }
}

.color-danger {
  &,
  &.fa::before {
    color: $danger !important;
  }
}

.list-inline {
  > li {
    display: inline-block;
    padding-right: $list-inline-margin;
    padding-left: $list-inline-margin;
  }
}

.list-inline-bulleted {
  > li:not(:first-child)::before {
    margin-right: $list-inline-margin;
    margin-left: $list-inline-margin * -2;
    content: "•";
  }
}

.required::after {
  color: #aa0000;
  content: " *";
}

address {
  margin-bottom: 0;
}

.label + .label {
  margin-left: 2px;
}

hr.thin {
  margin-top: 4px;
  margin-bottom: 4px;
}

pre.unpre {
  font-family: inherit;
  font-size: inherit;
  white-space: pre-wrap;
}
