@import "~@comanage/bootstrap/variables";

.sidebar {
  height: 100%;

  @include media-breakpoint-down(lg) {
    z-index: 1;

    left: -20em;
    width: min(90vw, 20em);
    transition: left 300ms ease-in-out;

    &.open {
      left: 0;
    }
  }

  .navbar {
    height: 100%;
    padding-bottom: 1em;

    .nav {
      width: 100%;
    }
  }

  a {
    color: $gray-100;

    &:hover {
      background-color: $gray-800;
      color: $gray-200;
    }
  }
}
