// Fonts from Google
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@import "~@comanage/bootstrap";

@import "~select2/select2.css";
@import "./select2";
//@import "~bootstrap-switch/src/less/bootstrap3/bootstrap-switch";
//@import "~bootstrap-datepicker/less/datepicker3";

@import "~jquery-ui-bootstrap/jquery.ui.theme.css";
@import "~jquery-ui-bootstrap/jquery.ui.theme.font-awesome.css";

// Set a sane base font size
html {
  font-size: 14px;
}

//
//And now, our custom code
//
@import "./type";
@import "./layout";
@import "./search";
@import "./navbar";
@import "./accounting";
@import "./workorder";
@import "./invoice";
@import "./notification";
@import "./people";
@import "./index";
@import "./dashboard";
@import "./attachments";
@import "./privacy";
@import "./zenbox";
@import "./events/events";
@import "./comanage/easymde";
@import "./comanage/password-strength";
@import "./form-fixes";
@import "./loading";
@import "./sidebar";
@import "./list-groups";
