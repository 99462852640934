@import "~@comanage/bootstrap/variables";

//Universal Search
.search-form .select2-container {
  @include media-breakpoint-up(sm) {
    width: 100%;
  }

  .select2-choice .select2-arrow {
    width: 30px;
    height: 29px;

    b {
      @extend .fa;
      @extend .fa-fw;
      position: relative;
      width: 20px;

      background: none;
    }

    b::before {
      position: absolute;
      display: block;
      width: 30px;
      height: 30px;
      margin-top: 7px;
      content: $fa-var-search;
    }
  }
}

.search-results-dropdown.select2-drop-active {
  @include media-breakpoint-down(sm) {
    padding-top: 5px;
    border: 1px solid #5897fb;
    border-radius: 5px;
  }
}

.search-results-dropdown.select2-container .select2-results,
.search-results-dropdown .select2-results {
  @media (min-height: 1200px) {
    max-height: 800px;
  }

  @media (min-height: 800px) {
    max-height: 600px;
  }

  @media (min-height: 600px) {
    max-height: 400px;
  }

  padding-right: 0;

  padding-left: 0;
  margin-right: 0;

  .select2-result {
    &.select2-highlighted {
      background-color: #add8e6;
      color: #000000;
    }

    .select2-result-label h4 {
      padding-left: 10px;
      border-bottom: 1px $nav-tabs-border-color solid;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .select2-no-results,
  .select2-searching {
    padding-right: 0;
    padding-left: 0;
    margin-right: 7px;
    margin-left: 7px;
  }

  .select2-result-with-children > div {
    padding-right: 0;
    padding-left: 0;
    background: linear-gradient(#ffffff, $nav-tabs-border-color);
  }
}

.input-group {
  .select2-choices {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.select2-hidden-accessible {
  display: none;
}

.search-results {
  position: absolute;
  display: none;
  padding-top: 50px;
  border: 1px #000000 solid;
  background-color: #ffffff;
}
