@import "~@comanage/bootstrap/variables";
@import "~font-awesome/scss/variables";
@import "~font-awesome/scss/core";
@import "~font-awesome/scss/larger";
@import "~font-awesome/scss/fixed-width";
@import "~font-awesome/scss/animated";

.btn[data-loading] {
  position: relative;
  padding: 6px 24px;
  transition: 500ms padding ease-out;

  &::after {
    @extend .fa;
    @extend .fa-spin;
    position: absolute;
    right: 5px;

    // hacky fix to get rid of the slight offset to the spinning
    margin-top: -0.5px;

    content: $fa-var-circle-o-notch;
    filter: blur(0);
    font-size: (14px * 1.5);
    opacity: 0;
    transition: 400ms opacity ease-in;
  }
}

.btn[data-loading="true"] {
  padding: 6px 36px 6px 12px;

  &::after {
    opacity: 1;
  }
}
