/* stylelint-disable selector-class-pattern */

@import "~@comanage/bootstrap/variables";

table.work-orders {
  $work-order-border-color: #a3a8b3;
  border-bottom-color: $work-order-border-color;

  tbody {
    border-color: $work-order-border-color;
  }
}

.workorder-Medium td {
  background-color: shift-color($success, $alert-bg-scale) !important;
}

.workorder-Urgent td {
  background-color: shift-color($warning, $alert-bg-scale) !important;
}

.workorder-Emergency td {
  background-color: shift-color($danger, $alert-bg-scale) !important;
}

//Votes come from here
.vote {
  .vote-up,
  .vote-down {
    color: $text-muted;

    i {
      @extend .fa;
      @extend .fa-fw;
    }
  }

  .vote-up {
    i::before {
      content: $fa-var-thumbs-up;
    }

    &:hover {
      color: $success;
    }
  }

  .vote-down {
    i::before {
      content: $fa-var-thumbs-down;
    }

    &:hover {
      color: $danger;
    }
  }

  &.vote-U {
    .vote-up {
      color: $success;

      &:hover {
        color: mix($success, $text-muted);
      }
    }
  }

  &.vote-D {
    .vote-down {
      color: $danger;

      &:hover {
        color: mix($danger, $text-muted);
      }
    }
  }
}
