@import "~@comanage/bootstrap/variables";

.navbar-nav .dropdown-menu {
  min-width: max-content;
}

.navbar-nav.navbar-separators .nav-item:not(:last-of-type) {
  border-right: 1px $nav-tabs-border-color solid;
}

@media print {
  .navbar {
    display: none;
  }
}
