#privacy_policy {
  section {
    padding-right: 15px;
    padding-left: 15px;

    h1 {
      font-size: 2.5em;
    }

    h2 {
      font-size: 1.75em;
    }

    p {
      padding-left: 15px;
    }
  }
}
