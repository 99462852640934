@import "~@comanage/bootstrap/variables";

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    padding: inherit;
    border: inherit;
    background-color: transparent;
    border-radius: inherit;
    box-shadow: inherit;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    outline: inherit;
    text-decoration: none;
    vertical-align: inherit;
  }
}

table.index {
  thead {
    th.sort-column {
      user-select: none;

      &:hover {
        cursor: pointer;
      }

      &[data-sort="asc"] {
        &::after {
          content: $fa-var-sort-asc;
        }
      }

      &[data-sort="desc"] {
        &::after {
          content: $fa-var-sort-desc;
        }
      }

      &::after {
        @include fa-icon();
        padding-left: 5px;
        content: $fa-var-sort;
      }
    }
  }

  tbody {
    tr {
      td {
        max-height: $line-height-base;
        text-overflow: ellipsis;
      }
    }
  }

  &.loading {
    tbody {
      opacity: 0.5;
    }

    &::before {
      position: absolute;
      z-index: 100000;
      width: 100%;
      content: "Loading...";
      font-size: 3em;
      opacity: 0.6;
      text-align: center;
    }
  }
}

@each $color, $value in $theme-colors {
  .fill-#{$color} {
    fill: $value;
  }
}
