/* stylelint-disable selector-class-pattern */
@import "~@comanage/bootstrap/variables";
@import "~codemirror/lib/codemirror.css";
@import "~easymde/src/css/easymde.css";

.CodeMirror-fullscreen {
  margin-top: 22px;
}

.EasyMDEContainer .CodeMirror,
.CodeMirror {
  padding: 2px 5px;
  border: 1px solid $input-border-color;

  .CodeMirror-placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }
}
