.attachments {
  .attachment {
    .filename {
      min-width: 200px;
    }

    .date {
      width: 100px;
      text-align: right;
    }
  }

  &.dragging .attachment {
    background-color: #ffffaa !important;
  }
}
